<template>
  <div class="UpdateTradePassword1">
    <div class="font">
      <span>修改交易密码</span>
    </div>
    <div class="font1">
      <span>6位不连续且不重复的数字</span>
    </div>
    <input v-model= "queryParams.oldTradePassword" class="password" type="text" name="fname">
    <input v-model= "queryParams.tradePassword" class="password" type="text" name="fname">
    <input v-model= "queryParams.tradePasswordCopy" class="password" type="text" name="fname">
    <div class="sub">
      <span @click="update()" class="font2">确认更改</span>
    </div>
  </div>
</template>

<script>
import { updateTradePassword } from "@/api/updatePassword1";
export default {
  name: "UpdateTradePassword1",
  components: {},
  data() {
    return {
      queryParams: {
        oldTradePassword: null,
        tradePassword: null,
        tradePasswordCopy: null,
        tradePwdFlag	: "1"
      }
    }
  },
  methods: {
    update(){
      updateTradePassword(this.queryParams).then((res) => {
        alert(res.data);
      });
    }
  }
};
</script>

<style scoped>
.UpdateTradePassword1 {
  width: 930px;
  height: 1366px;
  opacity: 1;
  background: #ffffff;
  margin-left: 390px;
  margin-top: 64px;
}

.font {
  width: 144px;
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
  font-weight: 400;
  text-align: CENTER;
  color: #25293d;
  line-height: 24px;
  margin-left: 391px;
  margin-top: 301px;
}

.font1 {
  width: 274px;
  height: 14px;
  opacity: 1;
  font-size: 14px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #bdc0cb;
  line-height: 14px;
  margin-top: 22px;
  margin-left: 326px;
}

.password {
  width: 450px;
  height: 50px;
  opacity: 1;
  border: 1px solid #e0e2e8;
  border-radius: 4px;
  margin-top: 62px;
  margin-left: 238px;
}
.sub{
  width: 450px;
  height: 50px;
  opacity: 1;
  background: linear-gradient(96deg,#f7d9b7 0%, #eabf96 100%);
  border-radius: 4px;
  text-align: center;
  line-height: 50px;
  margin-top: 62px;
  margin-left: 238px;
}
.font2{
  width: 64px;
  height: 16px;
  opacity: 1;
  font-size: 16px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
  font-weight: 400;
  text-align: CENTER;
  color: #b88141;
  line-height: 16px;
}
</style>
