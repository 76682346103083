import request from '@/utils/request'

// 修改登录密码
export function updatePassword(data) {

  return request({
    url: '/api/sale/securitycenter/editPwd',
    method: 'post',
    data
  })
}

// 修改交易密码
export function updateTradePassword(data) {

  return request({
    url: '/api/sale/securitycenter/editTradePwd',
    method: 'post',
    data
  })
}




